<template>
  <div class="flex flex-col gap-2">
    <ArrowButton
      background="#ccc"
      color="#000"
      text="ingreso trabajo"
      @onClick="goToIngresoTrabajo"
    />
    <ArrowButton
      background="#ccc"
      color="#000"
      text="evaluación semanal"
      @onClick="goToEvaluacionSemanal"
    />
    <ArrowButton background="#ccc" color="#000" text="pendientes" @onClick="goToPendientes" />
    <ArrowButton
      background="#ccc"
      color="#000"
      text="ingresar cliente"
      @onClick="goToIngresarCliente"
    />
    <ArrowButton background="#ccc" color="#000" text="rankings" @onClick="goToRankings" />
  </div>
</template>

<script>
import ArrowButton from '@/components/shared/ArrowButton.vue'
export default {
  name: 'Director',
  components: { ArrowButton },
  methods: {
    goToIngresoTrabajo() {
      this.$router.push({ name: 'IngresoTrabajo' })
    },
    goToEvaluacionSemanal() {
      this.$router.push({ name: 'EvaluacionSemanal' })
    },
    goToPendientes() {
      this.$router.push({ name: 'Pendientes' })
    },
    goToIngresarCliente() {
      this.$router.push({ name: 'IngresarCliente' })
    },
    goToRankings() {
      this.$router.push({ name: 'Rankings' })
    }
  }
}
</script>
